import WsProvider from './provider/WSProvider'


var serviceName = "webservis";
var moduleName = "proje";


var projeService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    projeListesi(durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "yoneticininProjeleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeTarihGetir(projeAtamaID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeAtamaID,
            serviceName: moduleName,
            methodName: "projeSozlesmeTarihleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmePersonelGetir(projeAtamaID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeAtamaID,
            serviceName: moduleName,
            methodName: "projeSozlesmePersonelleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    tarihGuncelle(projeAtamaID, baslamaTarihi, tamamlanmaTarihi) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeAtamaID,
            baslamaTarihi,
            tamamlanmaTarihi,
            serviceName: moduleName,
            methodName: "sozlesmeTarihGuncelle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    gecikenGuncelle() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: "surec",
            methodName: "gecikenleriGuncelle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    performansdegerlendir(musteriID, projeID, sozlesmeID, yoneticiID, yildiz, aciklama) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriID,
            projeID,
            sozlesmeID,
            yoneticiID,
            yildiz,
            aciklama,
            serviceName: "surec",
            methodName: "personelDegerlendir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    personelDegerlendirmeListesi(baslangic, limit, personelDegerlendirmeDurumu) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            baslangic,
            limit,
            personelDegerlendirmeDurumu,
            serviceName: "surec",
            methodName: "personelDegerlendirmeListesi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    personelDegerlendirmeDurumDegistir(personelDegerlendirmeID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            personelDegerlendirmeID,
            durum,
            serviceName: "surec",
            methodName: "personelDegerlendirmeDurumDegistir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    yoneticininProjeDurumDegistir(projeID, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            durum,
            serviceName: "surec",
            methodName: "yoneticininProjeDurumDegistir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    degerlendirmeFiltre(personelAdi, degerlendirenAdi, musteriAdi, projeBaslik, sozlesmeBaslik, yildiz, aciklama, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            personelAdi,
            degerlendirenAdi,
            musteriAdi,
            projeBaslik,
            sozlesmeBaslik,
            yildiz,
            aciklama,
            baslangic,
            limit,
            serviceName: "surec",
            methodName: "personelDegerlendirmeListesiFiltre"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    personGuncelle(projeAtamaID, yoneticiID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeAtamaID,
            yoneticiID,
            serviceName: moduleName,
            methodName: "sozlesmePersonelGuncelle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeRapor() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "projeRapor"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    genelRapor() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "genelRapor"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ulkeGetir() {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "ulkeGetir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ilGetir(ulkeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            ulkeID,
            serviceName: moduleName,
            methodName: "ilGetir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    ilceGetir(ilID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            ilID,
            serviceName: moduleName,
            methodName: "ilceGetir"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeSozlesmeleri(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeSozlesmeleri"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeGrupluSozlesmeler(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeGrupluSozlesmeler"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeDetay(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeDetay"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    sozlesmeListesi(projeID) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeSozlesmeleri2"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeEkle(sozlesmeAtamalari, musteriID, projeBaslik, adresBaslik, ulkeID, ilID, ilceID, alan, baslangicTarihi, bitisTarihi, durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec")
        var param = {
            token: userDataBGSurec,
            sozlesmeAtamalari: sozlesmeAtamalari,
            musteriID: musteriID,
            projeBaslik: projeBaslik,
            adresBaslik: adresBaslik,
            ulkeID: ulkeID,
            ilID: ilID,
            ilceID: ilceID,
            alan: alan,
            baslangicTarihi: baslangicTarihi,
            bitisTarihi: bitisTarihi,
            durum: durum,
            methodName: "projeEkle",
            serviceName: moduleName
        }

        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        });
        return result;
    },
    projeFilter(musteriAdi, projeBaslik, alan, baslangicTarihi, bitisTarihi, durum, baslangic, limit) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            musteriAdi,
            projeBaslik,
            alan,
            baslangicTarihi,
            bitisTarihi,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "yoneticininProjeleriFilter"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeSayisi(durum) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            durum: durum,
            serviceName: moduleName,
            methodName: "projeSayisi"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeGuncelle1(projeID, updateList) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            updateList,
            serviceName: moduleName,
            methodName: "projeDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeGuncelle(projeID, updateList, sozlesmeAtamalari) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeID,
            updateList,
            sozlesmeAtamalari,
            serviceName: moduleName,
            methodName: "projeDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
    projeSozlesmeKapsamDuzenle(projeAtamaID, updateList) {
        var userDataBGSurec = localStorage.getItem("userDataBGSurec");
        var param = {
            token: userDataBGSurec,
            projeAtamaID,
            updateList,
            serviceName: moduleName,
            methodName: "projeSozlesmeKapsamDuzenle"
        }
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },
}

export default projeService