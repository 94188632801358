import WsProvider from "./provider/WSProvider";

const serviceName = "webservis";
const moduleName = "proje";
const projeService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,
    projeListesi(durum, baslangic, limit, tur) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            tur,
            serviceName: moduleName,
            methodName: "projeListesi",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeRapor() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "projeRapor",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    genelRapor() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "genelRapor",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    ulkeSehirListele() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "ulkeSehirListele",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    ulkeGetir() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "ulkeGetir",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    ilGetir(ulkeID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            ulkeID,
            serviceName: moduleName,
            methodName: "ilGetir",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    ilceGetir(ilID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            ilID,
            serviceName: moduleName,
            methodName: "ilceGetir",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeSozlesmeleri(projeID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeSozlesmeleri",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeGrupluSozlesmeler(projeID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeGrupluSozlesmeler",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    personelProjeSozlesmeleri(projeID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "personelProjeSozlesmeleri",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeDetay(projeID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            serviceName: moduleName,
            methodName: "projeDetay",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeEkle(
        sozlesmeAtamalari,
        musteriID,
        projeBaslik,
        adresBaslik,
        ulkeID,
        ilID,
        ilceID,
        alan,
        ada,
        parsel,
        baslangicTarihi,
        bitisTarihi,
        tur,
        durum
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            // sozlesmeAtamalari: sozlesmeAtamalari,
            musteriID: musteriID,
            projeBaslik: projeBaslik,
            adresBaslik: adresBaslik || "",
            ulkeID: ulkeID || "",
            ilID: ilID || "",
            ilceID: ilceID || "",
            alan: alan || "",
            ada: ada || "",
            parsel: parsel || "",
            baslangicTarihi: baslangicTarihi,
            bitisTarihi: bitisTarihi,
            tur: tur,
            durum: durum,
            methodName: "projeEkle",
            serviceName: moduleName,
        };
        if (sozlesmeAtamalari.length > 0) {
            sozlesmeAtamalari.forEach((item, i) => {
                param[`sozlesmeKapsamAtama[${i}][sozlesmeID]`] = item.sozlesmeID;
                param[`sozlesmeKapsamAtama[${i}][yuzdeDeger]`] = item.yuzdeDeger || "";
                param[`sozlesmeKapsamAtama[${i}][baslamaTarihi]`] =
                    item.baslamaTarihi || "";
                param[`sozlesmeKapsamAtama[${i}][baslamaSuresi]`] =
                    item.baslamaSuresi || item.baslangicSuresi || "";
                param[`sozlesmeKapsamAtama[${i}][isSuresi]`] = item.isSuresi || "";
                param[`sozlesmeKapsamAtama[${i}][tamamlanmaTarihi]`] =
                    item.tamamlanmaTarihi || item.isTeslimTarihi || "";
                param[`sozlesmeKapsamAtama[${i}][durum]`] =
                    item.durum == "1" ? 10 : "0" || "";
            });
        }
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeFilter(
        musteriAdi,
        projeBaslik,
        alan,
        ada,
        parsel,
        konum,
        baslangicTarihi,
        bitisTarihi,
        durum,
        baslangic,
        limit
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            musteriAdi,
            projeBaslik,
            alan,
            ada,
            parsel,
            konum,
            baslangicTarihi,
            bitisTarihi,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "projeArama",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeSayisi(durum) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum: durum,
            serviceName: moduleName,
            methodName: "projeSayisi",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeGuncelle1(projeID, updateList) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            updateList,
            serviceName: moduleName,
            methodName: "projeDuzenle",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeGuncelle(projeID, updateList, sozlesmeAtamalari) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            updateList,
            sozlesmeAtamalari,
            serviceName: moduleName,
            methodName: "projeDuzenle",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    projeSozlesmeKapsamDuzenle(projeAtamaID, updateList) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeAtamaID,
            updateList,
            serviceName: moduleName,
            methodName: "projeSozlesmeKapsamDuzenle",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    turnEtutIntoProject(projeID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            projeID,
            status: 10,
            serviceName: moduleName,
            methodName: "projeTurDegistir",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
};

export default projeService;
