import Vue from 'vue'
import Router from 'vue-router'

//apps
import Dashboard from '../views/apps/Dashboard.vue'
import Firma from './firma'
import Yetki from './yetki'
import Atama from './atama'
import Sayfa from './sayfa'
import Pop from './pop'
import Iletisim from './iletisim'
import Haber from './haber'
import Slider from './slider'
import Rakam from './rakam'
import Galeri from './galeri'
import SSS from './sss'
import Menu from './menu'
import Musteri from './musteri'
import Proje from './proje'
import Personel from './personel'
import Kapsam from './kapsam'
import Sozlesme from './sozlesme'
import Rapor from './rapor'
import Tatil from './tatil'
import Gecikme from './gecikme'
import Degerlendirme from './degerlendirme'
import Etut from './etut'
import Tutanak from './tutanak'
import Faaliyet from './faaliyet'
import Celik from "./celik";
import Ozluk from "./ozluk";

//pages
import Login from '../views/pages/authentication/Login.vue'
import Register from '../views/pages/authentication/Register.vue'
import ForgotPassword from '../views/pages/authentication/ForgotPassword.vue'
import Profile from '../views/pages/Profile.vue'
import NotFound from '../views/pages/NotFound.vue'

import layouts from '../layout'
import store from '../store'


Vue.use(Router)
Vue.component('profile', Profile);

const router = new Router({
    mode: 'hash',
    // base: '/bgsurecler/panel/',
    base: '/bgpanel/',
    // base: data.PUBLIC_PATH,
    routes: [
        {
            path: '/',
            alias: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['app']
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                auth: true,
                layout: layouts.navLeft,
                searchable: true,
                tags: ['pages']
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                layout: layouts.contenOnly
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                layout: layouts.contenOnly
            }
        },
        {
            path: '/sifremi-unuttum',
            name: 'sifremi-unuttum',
            component: ForgotPassword,
            meta: {
                layout: layouts.contenOnly
            }
        },
        {
            path: '/logout',
            beforeEnter(to, from, next) {
                localStorage.removeItem("userDataBGSurec");
                auth.logout()
                next({path: '/login'})
            }
        },
        Firma,
        Yetki,
        Atama,
        Sayfa,
        Pop,
        Iletisim,
        Haber,
        Slider,
        Rakam,
        Galeri,
        SSS,
        Menu,
        Musteri,
        Proje,
        Personel,
        Kapsam,
        Sozlesme,
        Rapor,
        Tatil,
        Gecikme,
        Degerlendirme,
        Etut,
        Tutanak,
        Faaliyet,
        Celik,
        Ozluk,
        {
            path: '*',
            name: 'not-found',
            component: NotFound,
            meta: {
                layout: layouts.contenOnly
            }
        }
    ]
})

const l = {
    contenOnly() {
        store.commit('setLayout', layouts.contenOnly)
    },
    set(layout) {
        store.commit('setLayout', layout)
    }
}

const auth = {
    loggedIn() {
        return store.getters.isLogged
    },
    logout() {
        store.commit('setLogout')
    }
}

//insert here login logic
router.beforeEach((to, from, next) => {
    let authrequired = false
    if (to && to.meta && to.meta.auth)
        authrequired = true
    if (authrequired) {
        if (auth.loggedIn()) {
            if (to.name === 'login') {
                next({name: 'dashboard'})
            } else {
                next()
            }
        } else {
            if (to.name !== 'login') {
                next({name: 'login'})
            }
            next()
        }
    } else {
        if (auth.loggedIn() && to.name === 'login') {
            next({name: 'dashboard'})
        } else {
            next()
        }
    }
    if (to && to.meta && to.meta.layout) {
        l.set(to.meta.layout)
    }
})

router.afterEach((to, from) => {
    setTimeout(() => {
        store.commit('setSplashScreen', false)
    }, 500)
})

export default router