import WsProvider from "./provider/WSProvider";

const serviceName = "webservis";
const moduleName = "yonetim";

const yoneticiService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    girisYap(kullaniciAdi, sifre) {
        const param = {
            user: kullaniciAdi,
            pwd: sifre,
            methodName: "logIn",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yetkiAtama(yoneticiID, atanacakYetkiler) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiID: yoneticiID,
            atanacakYetkiler: atanacakYetkiler,
            methodName: "yetkiAtama",
            serviceName: moduleName,
        };

        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    sifreSmsGonder(yoneticiID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiID: yoneticiID,
            methodName: "sifreSmsGonder",
            serviceName: moduleName,
        };

        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    gorevAtama(yoneticiID, musteriID, projeID, sozlesmeIDler) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiID: yoneticiID,
            musteriID: musteriID,
            projeID: projeID,
            sozlesmeIDler: sozlesmeIDler,
            methodName: "gorevAtama",
            serviceName: moduleName,
        };

        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    gorevAtamaKategori(yoneticiID, musteriID, projeID, sozlesmeKategoriIDler) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiID: yoneticiID,
            musteriID: musteriID,
            projeID: projeID,
            sozlesmeKategoriIDler: sozlesmeKategoriIDler,
            methodName: "gorevAtamaKategori",
            serviceName: moduleName,
        };

        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yetkiListesi() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "yetkiListesi",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticilerinYetkileri() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "yoneticilerinYetkileri",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiGorevListele() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "yoneticiGorevListele",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiGorevListele2() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            serviceName: moduleName,
            methodName: "yoneticiGorevListele2",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiTanimlama(
        yonetimKodu,
        yetkiTuru,
        sozlesmeKategoriID,
        tcNo,
        resim,
        yoneticiAdi,
        sifre,
        adSoyad,
        gorev,
        eposta,
        cepTelefonu,
        sabitTelefon,
        dahiliTelefon
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yonetimKodu: yonetimKodu,
            yetkiTuru: yetkiTuru,
            sozlesmeKategoriID: sozlesmeKategoriID,
            TC: tcNo,
            adSoyad: adSoyad,
            gorev: gorev,
            eposta: eposta,
            resim: resim,
            cepTelefonu: cepTelefonu,
            sabitTelefon: sabitTelefon,
            dahiliTelefon: dahiliTelefon,
            yoneticiAdi: yoneticiAdi,
            sifre: sifre,
            durum: 1,
            methodName: "yoneticiEkle",
            serviceName: moduleName,
        };

        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiTanimlama2(
        yonetimKodu,
        yetkiTuru,
        sozlesmeKategoriID,
        tcNo,
        resim,
        yoneticiAdi,
        adSoyad,
        gorev,
        eposta,
        cepTelefonu,
        sabitTelefon,
        dahiliTelefon
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yonetimKodu: yonetimKodu,
            yetkiTuru: yetkiTuru,
            sozlesmeKategoriID: sozlesmeKategoriID,
            TC: tcNo,
            adSoyad: adSoyad,
            gorev: gorev,
            eposta: eposta,
            resim: resim,
            cepTelefonu: cepTelefonu,
            sabitTelefon: sabitTelefon,
            dahiliTelefon: dahiliTelefon,
            yoneticiAdi: yoneticiAdi,
            durum: 1,
            methodName: "yoneticiEkle",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiFilter(
        personelAdi,
        personelKullaniciAdi,
        yetkiTuru,
        sozlesmeKategoriAdi,
        telefon,
        dahili,
        sabit,
        durum,
        baslangic,
        limit
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            personelAdi,
            personelKullaniciAdi,
            yetkiTuru,
            sozlesmeKategoriAdi,
            telefon,
            dahili,
            sabit,
            durum,
            baslangic,
            limit,
            serviceName: moduleName,
            methodName: "yoneticiFilter",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiGuncelle(
        yoneticiID,
        sozlesmeKategoriID,
        resim,
        adSoyad,
        eposta,
        cepTelefonu,
        sabitTelefon,
        dahiliTelefon,
        rol,
        yoneticiAdi
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiID: yoneticiID,
            sozlesmeKategoriID: sozlesmeKategoriID,
            resim: resim,
            adSoyad: adSoyad,
            eposta,
            yoneticiAdi,
            cepTelefonu: cepTelefonu,
            sabitTelefon: sabitTelefon,
            dahiliTelefon: dahiliTelefon,
            rol,
            durum: 1,
            methodName: "yoneticiGuncelle",
            serviceName: moduleName,
        };

        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    atamaDetay(yoneticiID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiID,
            serviceName: moduleName,
            methodName: "atamaDetay",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    gorevAtamaSil(gorevAtamaID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            gorevAtamaID,
            serviceName: moduleName,
            methodName: "gorevAtamaSil",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiListe(durum, baslangic, limit, yetkiTuru) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            yetkiTuru,
            methodName: "listele",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiListe2(sozlesmeKategoriID, durum, baslangic, limit) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum,
            baslangic,
            limit,
            methodName: "listele",
            serviceName: moduleName,
        };
        if (sozlesmeKategoriID !== "0" || sozlesmeKategoriID !== "-1")
            param.sozlesmeKategoriID = sozlesmeKategoriID;
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiCount(durum) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum,
            methodName: "yoneticiSayisi",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    getAccount() {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            methodName: "getAccount",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiSifreGuncelle(yoneticiAdi, yeniSifre) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yoneticiAdi: yoneticiAdi,
            yeniSifre: yeniSifre,
            methodName: "yoneticiSifreGuncelle",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    // yoneticinin kendi şifresini güncellediği metot
    sifreGuncelle(eskiSifre, yeniSifre, yeniSifreTekrar) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            eskiSifre: eskiSifre,
            yeniSifre: yeniSifre,
            yeniSifreTekrar: yeniSifreTekrar,
            methodName: "sifreGuncelle",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiAktifYap(yoneticiID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yonetimID: yoneticiID,
            durum: 1,
            methodName: "aktifPasifEt",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiPasifYap(yoneticiID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            yonetimID: yoneticiID,
            durum: 0,
            methodName: "aktifPasifEt",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    musterideGorevliPersoneller(musteriID) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            musteriID,
            serviceName: moduleName,
            methodName: "yoneticiMusterileri",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    yoneticiSearch(params) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            param: params,
            serviceName: moduleName,
            methodName: "musterininYoneticileriListele",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    getYoneticiList(baslangic, limit, durum, yetkiTuru) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            baslangic,
            limit,
            durum,
            yetkiTuru,
            methodName: "yoneticiListesi",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    if (response) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    sifremiUnuttum() {
    },
};
export default yoneticiService;
