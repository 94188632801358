<template>
  <div class="page-table only-y mainDiv mb-30">
    <div
        class="page-header --medium animated fadeInUp"
        style="min-height: 200px"
    >
      <div class="anasayfa">
        <el-row v-if="isDesktop" class="collapse-list">
          <el-row type="flex" style="flex-wrap: wrap">
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 geri-proje-area"
                v-if="
                yetkiTuru === '1' ||
                (yetkiTuru === '2' && yetkiListesi.includes('YK-projeR200'))
              "
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="geriProje.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Geciken Projeler"
                    name="1"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown geciken-proje"
                      style="height: 400px"
                  >
                    <el-table
                        :data="geriProje"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop=" musteriAdi" label="Müşteri">
                        <template #default="scope">
                          <p class="m-0 ml-1">{{ scope.row.musteriAdi }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column prop="projeBaslik" label="Proje">
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.projeBaslik }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="toplamGun"
                          label="Top. Gün"
                          width="80"
                      >
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.toplamProjeSuresi }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="kalanGun"
                          label="Kal. Gün"
                          width="80"
                      >
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.kalanSure }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="projeID"
                          label="İşlem"
                          width="80"
                          align="center"
                      >
                        <template #default="scope">
                          <el-tooltip
                              content="Müşteri Detay"
                              :open-delay="500"
                              placement="bottom"
                              v-if="
                              yetkiTuru === '1' ||
                              (yetkiTuru === '2' &&
                                yetkiListesi.includes('YK-musteriP100'))
                            "
                          >
                            <el-button
                                @click="detayPage(scope.row.musteriID, scope.row)"
                                style="color: #1087d6; padding-right: 5px"
                                type="text"
                                icon="mdi mdi-el-icon-view mdi-24px"
                                class="p-10"
                            >
                              <i class="mdi mdi-cursor-default-click"></i>
                            </el-button>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 normal-proje-area"
                v-if="
                yetkiTuru === '1' ||
                (yetkiTuru === '2' && yetkiListesi.includes('YK-projeR200'))
              "
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="normalProje.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Projeler"
                    name="2"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown normal-proje"
                      style="height: 400px"
                  >
                    <el-table
                        :data="normalProje"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop=" musteriAdi" label="Müşteri">
                        <template #default="scope">
                          <p class="m-0 ml-1">{{ scope.row.musteriAdi }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column prop="projeBaslik" label="Proje">
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.projeBaslik }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="toplamGun"
                          label="Top. Gün"
                          width="80"
                      >
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.toplamProjeSuresi }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="kalanGun"
                          label="Kal. Gün"
                          width="80"
                      >
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.kalanSure }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column prop="projeID" label="İşlem" width="80">
                        <template #default="scope">
                          <el-tooltip
                              content="Müşteri Detay"
                              :open-delay="500"
                              placement="bottom"
                              v-if="
                              yetkiTuru === '1' ||
                              (yetkiTuru === '2' &&
                                yetkiListesi.includes('YK-musteriP100'))
                            "
                          >
                            <el-button
                                @click="detayPage(scope.row.musteriID, scope.row)"
                                style="color: #1087d6; padding-right: 5px"
                                type="text"
                                icon="mdi mdi-el-icon-view mdi-24px"
                                class="p-10"
                            >
                              <i class="mdi mdi-cursor-default-click"></i>
                            </el-button>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 etut-area"
                v-if="
                yetkiTuru === '1' ||
                (yetkiTuru === '2' && yetkiListesi.includes('YK-etutR200'))
              "
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="etutList.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Etütler"
                    name="3"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown etut-proje"
                      style="height: 400px"
                  >
                    <el-table
                        :data="etutList"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop=" musteriAdi" label="Müşteri">
                        <template #default="scope">
                          <p class="m-0 ml-1">{{ scope.row.musteriAdi }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column prop="projeBaslik" label="Proje">
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.projeBaslik }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="toplamGun"
                          label="Top. Gün"
                          width="80"
                      >
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.toplamProjeSuresi }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="kalanGun"
                          label="Kal. Gün"
                          width="80"
                      >
                        <template #default="scope">
                          <p class="m-0">{{ scope.row.kalanSure }}</p>
                        </template>
                      </el-table-column>
                      <el-table-column prop="projeID" label="İşlem" width="80">
                        <template #default="scope">
                          <el-tooltip
                              content="Müşteri Detay"
                              :open-delay="500"
                              placement="bottom"
                          >
                            <el-button
                                @click="detayPage(scope.row.musteriID, scope.row)"
                                style="color: #1087d6; padding-right: 5px"
                                type="text"
                                icon="mdi mdi-el-icon-view mdi-24px"
                                class="p-10"
                            >
                              <i class="mdi mdi-cursor-default-click"></i>
                            </el-button>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>

            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 gecikme-area"
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="gecikmeler.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Gecikmeye Düşen Personeller"
                    name="6"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown"
                      style="height: 256px"
                  >
                    <el-table
                        :data="gecikmeler"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop="adSoyad" label="Personel Adı"/>
                      <el-table-column prop="projeBaslik" label="Proje Adı"/>
                      <el-table-column prop="gecikenGun" label="Geciken Gün"/>
                      <el-table-column
                          prop="gecikmeID"
                          label="İşlem"
                          align="center"
                          width="80"
                      >
                        <template #default="scope">
                          <el-tooltip
                              content="Açıklama"
                              :open-delay="500"
                              placement="bottom"
                          >
                            <el-button
                                @click="openGecikme(scope.row)"
                                style="color: #1087d6; padding-right: 5px"
                                type="text"
                                icon="mdi mdi-comment-text-outline mdi-24px"
                            />
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 degerlendirme-area"
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="degerlendirmeler.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Personel Değerlendirme"
                    name="7"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown"
                      style="height: 256px"
                  >
                    <el-table
                        :data="degerlendirmeler"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column
                          prop="personelAdi"
                          label="Personel Adı"
                      />
                      <el-table-column
                          prop="degerlendirenAdi"
                          label="Değerlendiren"
                      />
                      <el-table-column prop="yildiz" label="Yıldız">
                        <template #default="scope">
                          <el-rate
                              :value="parseInt(scope.row.yildiz)"
                              disabled
                          ></el-rate>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="degerlendirenID"
                          label="İşlem"
                          align="center"
                          width="80"
                      >
                        <template #default="scope">
                          <el-tooltip
                              content="Detay"
                              :open-delay="500"
                              placement="bottom"
                          >
                            <el-button
                                @click="degerlendirmeDetay(scope.row)"
                                style="color: #1087d6; padding-right: 5px"
                                type="text"
                                icon="mdi mdi-comment-text-outline mdi-24px"
                            />
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 yonetici-area"
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="yoneticiListesi.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Son Eklenen 5 Kullanıcı"
                    name="4"
                >
                  <el-table
                      :data="yoneticiListesi"
                      border
                      style="width: 100%"
                      class="p-5"
                  >
                    <el-table-column
                        prop="adSoyad"
                        label="Ad Soyad"
                        width="180"
                    >
                      <template #default="scope">
                        <i style="line-height: 1.6" class="mdi mdi-account"></i>
                        <p class="m-0 ml-5" style="display: inline-flex">
                          {{ scope.row.adSoyad }}
                        </p>
                      </template>
                    </el-table-column>
                    <el-table-column prop="rol" label="Yetki Türü" width="100">
                      <template #default="scope">
                        {{
                          scope.row.rol == "1"
                              ? "Yönetici"
                              : scope.row.rol == "2"
                                  ? "Kullanıcı"
                                  : scope.row.rol == "3"
                                      ? "Personel"
                                      : ""
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="cepTel" label="Telefon"/>
                    <el-table-column
                        prop="ID"
                        label="İşlem"
                        align="center"
                        width="80"
                    >
                      <template #default="scope">
                        <el-tooltip
                            content="Personel Detay"
                            :open-delay="500"
                            placement="bottom"
                        >
                          <el-button
                              class="personelButon"
                              v-on:click="yoneticiDetay(scope.row)"
                              type="text"
                              size="mini"
                              icon="mdi mdi-eye mdi-24px"
                          >
                          </el-button>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 faaliyet-area"
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="faaliyetListesi.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Günlük Faaliyetler"
                    name="9"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown"
                      style="height: 256px"
                  >
                    <el-table
                        :data="faaliyetListesi"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop="firmaAdi" label="Firma"/>
                      <el-table-column prop="projeAdi" label="Proje"/>
                      <el-table-column prop="Başlık" label="Başlık"/>
                      <el-table-column prop="isAdi" label="İş Adı"/>
                      <el-table-column prop="tarih" label="Tarih">
                        <template #default="scope">
                          {{ scope.row.tarih.slice(0, 16) }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>

            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 tutanak-area"
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="tutanakListesi.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Son Tutanaklar"
                    name="8"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown"
                      style="height: 256px"
                  >
                    <el-table
                        :data="tutanakListesi"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop="konu" label="Konu"/>
                      <el-table-column prop="yetkiliAdSoyad" label="Yetkili"/>
                      <el-table-column prop="olusumTarihi" label="Tarih">
                        <template #default="scope">
                          {{ scope.row.olusumTarihi.slice(0, 16) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="tutanakID"
                          label="İşlem"
                          align="center"
                          width="80"
                      >
                        <template #default="scope">
                          <el-tooltip
                              content="Tutanak Detay"
                              :open-delay="500"
                              placement="left"
                          >
                            <el-button
                                @click="tutanakDetay(scope.row)"
                                type="text"
                                class="islem-button"
                                style="margin-right: 10px"
                                icon="mdi mdi-eye mdi-24px"
                            >
                            </el-button>
                          </el-tooltip>
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>
            <el-col
                :xl="8"
                :lg="8"
                :md="12"
                :sm="24"
                :xs="24"
                class="p-6 tatil-area"
            >
              <el-collapse
                  style="margin-top: 10px; position: relative"
                  v-model="activeCollapse"
              >
                <el-badge
                    :value="tatilList.length"
                    class="item baddge"
                ></el-badge>
                <el-collapse-item
                    class="card-shadow--medium p-5"
                    title="Resmi Tatiller"
                    name="5"
                >
                  <vue-scroll
                      class="box grow animated fadeInDown"
                      style="height: 256px"
                  >
                    <el-table
                        :data="tatilList"
                        border
                        style="width: 100%"
                        class="p-5"
                        stripe
                    >
                      <el-table-column prop="sebep" label="Özel Gün"/>
                      <el-table-column
                          prop="tamGun"
                          label="Tatil Türü"
                          width="100"
                          align="center"
                      >
                        <template #default="scope">{{
                            scope.row.tamGun == "1" ? "Tam Gün" : "Yarım Gün"
                          }}
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="gunMiktari"
                          label="Top. Gün"
                          width="80"
                          align="center"
                      />
                      <el-table-column prop="baslamaTarihi" label="Tarih">
                        <template #default="scope"
                        >{{ scope.row.baslamaTarihi.slice(0, 10) }} -
                          {{ scope.row.bitisTarihi.slice(0, 10) }}
                        </template>
                      </el-table-column>
                    </el-table>
                  </vue-scroll>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-row>
        </el-row>
        <el-row v-else class="mobile-dash">
          <el-col :span="8" class="mt-10">
            <el-badge :value="geriProje.length" class="item mobile-badge">
              <el-card
                  v-wave
                  class="m-0"
                  :style="{
                  borderBottom:
                    mobileSelectCard == 1 ? '1px solid #4CB9E7' : '',
                }"
              >
                <el-button
                    @click="mobileSelectCard = 1"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgb(166 216 251) 0%,
                      rgb(199 224 237) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                  "
                >
                  Geciken Projeler
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="normalProje.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 2"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgb(166 216 251) 0%,
                      rgb(199 224 237) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 2 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Projeler
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="etutList.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 3"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgb(166 216 251) 0%,
                      rgb(199 224 237) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 3 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Etütler
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="gecikmeler.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 4"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgba(173, 228, 219, 1) 0%,
                      rgba(221, 242, 253, 1) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 4 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Gecikmeye Düşen Personeller
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge
                :value="degerlendirmeler.length"
                class="item mobile-badge"
            >
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 5"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgba(173, 228, 219, 1) 0%,
                      rgba(221, 242, 253, 1) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 5 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Personel Değerlendirme
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="yoneticiListesi.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 6"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgba(173, 228, 219, 1) 0%,
                      rgba(221, 242, 253, 1) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 6 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Son Eklenen 5 Kullanıcı
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="tatilList.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 7"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgb(132 210 255) 0%,
                      rgba(221, 242, 253, 1) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 7 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Resmi Tatiller
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="tutanakListesi.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 8"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgb(132 210 255) 0%,
                      rgba(221, 242, 253, 1) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 8 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Son Tutanaklar
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <el-col :span="8" class="mt-10">
            <el-badge :value="faaliyetListesi.length" class="item mobile-badge">
              <el-card v-wave>
                <el-button
                    @click="mobileSelectCard = 9"
                    style="
                    background: linear-gradient(
                      180deg,
                      rgb(132 210 255) 0%,
                      rgba(221, 242, 253, 1) 100%
                    );
                    font-family: system-ui;
                    font-size: 14px;
                    white-space: break-spaces;
                  "
                    :style="{
                    borderBottom:
                      mobileSelectCard == 9 ? '2px solid #4CB9E7' : '',
                  }"
                >
                  Son Tutanaklar
                </el-button>
              </el-card>
            </el-badge>
          </el-col>
          <!-- List -->
          <el-col :span="24" v-if="mobileSelectCard === 1">
            <el-divider/>
            <vue-scroll class="box grow geciken-proje" style="height: 550px">
              <el-table
                  :data="geriProje"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop=" musteriAdi" label="Müşteri">
                  <template #default="scope">
                    <p class="m-0 ml-1">{{ scope.row.musteriAdi }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="projeBaslik" label="Proje">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.projeBaslik }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="toplamGun" label="Top. Gün" width="60">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.toplamProjeSuresi }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="kalanGun" label="Kal. Gün" width="55">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.kalanSure }}</p>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="projeID"
                    label="İşlem"
                    width="60"
                    align="center"
                >
                  <template #default="scope">
                    <el-tooltip
                        content="Müşteri Detay"
                        :open-delay="500"
                        placement="bottom"
                    >
                      <el-button
                          @click="detayPage(scope.row.musteriID, scope.row)"
                          style="color: #1087d6; padding-right: 5px"
                          type="text"
                          icon="mdi mdi-el-icon-view mdi-24px"
                          class="p-10"
                      >
                        <i class="mdi mdi-cursor-default-click"></i>
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 2">
            <el-divider/>
            <vue-scroll class="box grow normal-proje" style="height: 550px">
              <el-table
                  :data="normalProje"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop=" musteriAdi" label="Müşteri">
                  <template #default="scope">
                    <p class="m-0 ml-1">{{ scope.row.musteriAdi }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="projeBaslik" label="Proje">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.projeBaslik }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="toplamGun" label="Top. Gün" width="60">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.toplamProjeSuresi }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="kalanGun" label="Kal. Gün" width="55">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.kalanSure }}</p>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="projeID"
                    label="İşlem"
                    width="60"
                    align="center"
                >
                  <template #default="scope">
                    <el-tooltip
                        content="Müşteri Detay"
                        :open-delay="500"
                        placement="bottom"
                    >
                      <el-button
                          @click="detayPage(scope.row.musteriID, scope.row)"
                          style="color: #1087d6; padding-right: 5px"
                          type="text"
                          icon="mdi mdi-el-icon-view mdi-24px"
                          class="p-10"
                      >
                        <i class="mdi mdi-cursor-default-click"></i>
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 3">
            <el-divider/>
            <vue-scroll class="box grow etut-proje" style="height: 550px">
              <el-table
                  :data="etutList"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop=" musteriAdi" label="Müşteri">
                  <template #default="scope">
                    <p class="m-0 ml-1">{{ scope.row.musteriAdi }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="projeBaslik" label="Proje">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.projeBaslik }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="toplamGun" label="Top. Gün" width="60">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.toplamProjeSuresi }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="kalanGun" label="Kal. Gün" width="55">
                  <template #default="scope">
                    <p class="m-0">{{ scope.row.kalanSure }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="projeID" label="İşlem" width="60">
                  <template #default="scope">
                    <el-tooltip
                        content="Müşteri Detay"
                        :open-delay="500"
                        placement="bottom"
                    >
                      <el-button
                          @click="detayPage(scope.row.musteriID, scope.row)"
                          style="color: #1087d6; padding-right: 5px"
                          type="text"
                          icon="mdi mdi-el-icon-view mdi-24px"
                          class="p-10"
                      >
                        <i class="mdi mdi-cursor-default-click"></i>
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 4">
            <el-divider/>
            <vue-scroll class="box grow" style="height: 550px">
              <el-table
                  :data="gecikmeler"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop="adSoyad" label="Personel Adı"/>
                <el-table-column prop="projeBaslik" label="Proje Adı"/>
                <el-table-column
                    prop="gecikenGun"
                    label="Geciken Gün"
                    width="90"
                />
                <el-table-column
                    prop="gecikmeID"
                    label="İşlem"
                    align="right"
                    width="60"
                >
                  <template #default="scope">
                    <el-tooltip
                        content="Açıklama"
                        :open-delay="500"
                        placement="bottom"
                    >
                      <el-button
                          @click="openGecikme(scope.row)"
                          style="color: #1087d6; padding-right: 5px"
                          type="text"
                          icon="mdi mdi-comment-text-outline mdi-24px"
                      />
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 5">
            <el-divider/>
            <vue-scroll class="box grow" style="height: 550px">
              <el-table
                  :data="degerlendirmeler"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop="personelAdi" label="Personel Adı"/>
                <el-table-column
                    prop="degerlendirenAdi"
                    label="Değerlendiren"
                    width="125"
                />
                <el-table-column prop="yildiz" label="Yıldız">
                  <template #default="scope">
                    <el-rate
                        :value="parseInt(scope.row.yildiz)"
                        disabled
                    ></el-rate>
                  </template>
                </el-table-column>
                <el-table-column
                    prop="degerlendirenID"
                    label="İşlem"
                    align="right"
                    width="65"
                >
                  <template #default="scope">
                    <el-tooltip
                        content="Detay"
                        :open-delay="500"
                        placement="bottom"
                    >
                      <el-button
                          @click="degerlendirmeDetay(scope.row)"
                          style="color: #1087d6; padding-right: 5px"
                          type="text"
                          icon="mdi mdi-comment-text-outline mdi-24px"
                      />
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 6">
            <el-divider/>
            <vue-scroll class="box grow" style="height: 550px">
              <el-table
                  :data="yoneticiListesi"
                  border
                  style="width: 100%"
                  class="p-5"
              >
                <el-table-column prop="adSoyad" label="Ad Soyad" width="180">
                  <template #default="scope">
                    <i style="line-height: 1.6" class="mdi mdi-account"></i>
                    <p class="m-0 ml-5" style="display: inline-flex">
                      {{ scope.row.adSoyad }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="rol" label="Yetki Türü" width="100">
                  <template #default="scope">
                    {{
                      scope.row.rol == "1"
                          ? "Yönetici"
                          : scope.row.rol == "2"
                              ? "Kullanıcı"
                              : scope.row.rol == "3"
                                  ? "Personel"
                                  : ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column prop="cepTel" label="Telefon"/>
                <el-table-column prop="ID" label="İşlemler" align="center">
                  <template #default="scope">
                    <el-tooltip
                        content="Personel Detay"
                        :open-delay="500"
                        placement="bottom"
                    >
                      <el-button
                          class="personelButon"
                          v-on:click="yoneticiDetay(scope.row)"
                          type="text"
                          size="mini"
                          icon="mdi mdi-eye mdi-24px"
                      >
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 7">
            <el-divider/>
            <vue-scroll class="box grow" style="height: 550px">
              <el-table
                  :data="tatilList"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop="sebep" label="Özel Gün"/>
                <el-table-column
                    prop="tamGun"
                    label="Tatil Türü"
                    width="100"
                    align="center"
                >
                  <template #default="scope">{{
                      scope.row.tamGun == "1" ? "Tam Gün" : "Yarım Gün"
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                    prop="gunMiktari"
                    label="Top. Gün"
                    width="90"
                    align="center"
                />
                <el-table-column prop="baslamaTarihi" label="Tarih">
                  <template #default="scope"
                  >{{ scope.row.baslamaTarihi.slice(0, 10) }} -
                    {{ scope.row.bitisTarihi.slice(0, 10) }}
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 8">
            <el-divider/>
            <vue-scroll class="box grow" style="height: 550px">
              <el-table
                  :data="tutanakListesi"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop="konu" label="Konu"/>
                <el-table-column prop="yetkiliAdSoyad" label="Yetkili"/>
                <el-table-column prop="olusumTarihi" label="Tarih">
                  <template #default="scope">
                    {{ scope.row.olusumTarihi.slice(0, 16) }}
                  </template>
                </el-table-column>
                <el-table-column
                    prop="tutanakID"
                    label="İşlem"
                    width="60"
                    align="center"
                >
                  <template #default="scope">
                    <el-tooltip
                        content="Tutanak Detay"
                        :open-delay="500"
                        placement="left"
                    >
                      <el-button
                          @click="tutanakDetay(scope.row)"
                          type="text"
                          class="islem-button"
                          style="margin-right: 10px"
                          icon="mdi mdi-eye mdi-24px"
                      >
                      </el-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
          <el-col :span="24" v-if="mobileSelectCard === 9">
            <el-divider/>
            <vue-scroll class="box grow" style="height: 550px">
              <el-table
                  :data="faaliyetListesi"
                  border
                  style="width: 100%"
                  class="p-5"
                  stripe
              >
                <el-table-column prop="firmaAdi" label="Firma"/>
                <el-table-column prop="projeAdi" label="Proje"/>
                <el-table-column prop="Başlık" label="Başlık"/>
                <el-table-column prop="isAdi" label="İş Adı"/>
                <el-table-column prop="tarih" label="Tarih">
                  <template #default="scope">
                    {{ scope.row.tarih.slice(0, 16) }}
                  </template>
                </el-table-column>
              </el-table>
            </vue-scroll>
          </el-col>
        </el-row>
      </div>
    </div>
    <user-dialog
        v-if="dialogVisibleYonetici"
        :dialogvisible.sync="dialogVisibleYonetici"
        :userdata="userDataBGSurec"
    ></user-dialog>
    <gecikme-dialog
        v-if="dialogVisibleGecikme"
        :dialogvisible.sync="dialogVisibleGecikme"
        :userdata="userDataBGSurec"
    ></gecikme-dialog>
    <tutanak-detay
        v-if="dialogVisibleTutanak"
        :dialogvisible.sync="dialogVisibleTutanak"
        :userdata="userDataBGSurecTutanak"
    ></tutanak-detay>
    <personel-degerlendirme
        v-if="dialogVisibleDegerlendirme"
        :dialogvisible.sync="dialogVisibleDegerlendirme"
        :userdata="userDataBGSurecDegerlendirme"
    ></personel-degerlendirme>
  </div>
</template>

<script>
import notification from "../notification";
import functions from "../functions";
import projeService from "../WSProvider/ProjeService";
import MusteriRapor from "../views/apps/rapor/MusteriRapor.vue";
import kapsamService from "../WSProvider/KapsamService";
import yoneticiService from "@/WSProvider/YoneticiService";
import tatilService from "@/WSProvider/TatilService";
import personelService from "@/WSProvider/PersonelService";
import UserDialog from "@/components/UserDialog.vue";
import tutanakService from "@/WSProvider/TutanakService";
import GecikmeDialog from "@/components/GecikmeDialog.vue";
import TutanakDetay from "@/components/TutanakDetay.vue";
import PersonelDegerlendirme from "@/components/PersonelDegerlendirme.vue";
import EventBus from "@/components/event-bus";

var moment = require("moment");

export default {
  name: "AnaSayfa",
  components: {
    PersonelDegerlendirme,
    TutanakDetay,
    GecikmeDialog,
    UserDialog,
    MusteriRapor,
  },
  watch: {
    isDesktop(newVal) {
      if (newVal)
        this.activeCollapse = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
        ];
      else this.activeCollapse = [];
    },
  },
  data() {
    return {
      mobileSelectCard: 1,
      faaliyetListesi: [],
      tutanakListesi: [],
      totalTutanak: [],
      degerlendirmeler: [],
      totalDegerlendirme: [],
      gecikmeler: [],
      totalGecikme: [],
      yetkiListesi: [],
      yetkiTuru: "",
      isDesktop: false,
      secilenMusteri: "",
      secilenProje: "",
      sizes: 10,
      selectIcon: "1",
      page: 0,
      postPage: 0,
      total: 0,
      tatilTotal: 0,
      tatilList: [],
      yoneticiID: "",
      yoneticiListesi: [],
      musterilerList: [],
      projelerList: [],
      etutList: [],
      projeList: [],
      tarihler: [],
      geriProje: [],
      normalProje: [],
      sozlesmesiOlmayanProje: [],
      activeCollapse: [],
      dialogVisibleYonetici: false,
      dialogVisibleGecikme: false,
      dialogVisibleTutanak: false,
      dialogVisibleDegerlendirme: false,
      userDataBGSurec: {},
      userDataBGSurecTutanak: {},
      userDataBGSurecDegerlendirme: {},
    };
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      this.yoneticiID = this.$store.getters.getYoneticiID;
    }
  },
  mounted() {
    const self = this;
    if (this.yetkiTuru == 1) {
      this.getProjeListesi();
      this.getEtutListesi();
      this.getYoneticiListesi();
      this.getGecikenSozlesmeler();
      EventBus.$on("gecikmeListesi", () => self.getGecikenSozlesmeler());
    }
    if (this.yetkiTuru == 2) {
      this.getKapsamListesi();
    }
    if (this.yetkiTuru == 2 || this.yetkiTuru == 1) {
      this.getDegerlendirmeler();
      this.getTutanakList();
      EventBus.$on("personelDegerlendirmeList", () =>
          self.getDegerlendirmeler()
      );
    }
    // this.getFaaliyetListesi()
    this.getTatilListesi();
    window.addEventListener("resize", this.checkDevice);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDevice);
  },
  methods: {
    degerlendirmeDetay(data) {
      this.userDataBGSurecDegerlendirme = data;
      this.dialogVisibleDegerlendirme = true;
    },
    tutanakDetay(tutanak) {
      this.userDataBGSurecTutanak = tutanak;
      this.dialogVisibleTutanak = true;
    },
    openGecikme(sozlesme) {
      const musteriAdi = sozlesme.musteriAdi;
      const projeAdi = sozlesme.projeBaslik;
      this.userDataBGSurec = {
        sozlesme: sozlesme,
        musteriAdi,
        projeAdi,
      };
      this.dialogVisibleGecikme = true;
    },
    routeYonetici(title, scope) {
      if (scope) {
        this.$store.commit("changeYoneticiUpdateData", scope);
      }
      functions.routeSayfa(title, this);
    },
    yoneticiDetay(scope) {
      this.userDataBGSurec = scope;
      this.dialogVisibleYonetici = true;
    },
    checkDevice() {
      this.isDesktop = window.innerWidth >= 821;
    },

    detayPage(musteriID, proje) {
      if (
          this.yetkiTuru == 1 ||
          (this.yetkiTuru == 2 && this.yetkiListesi.includes("YK-musteriP100"))
      ) {
        this.$store.commit("changeMusteriDetayData", musteriID);
        this.$store.commit("changeProjeDetayData", proje);
        functions.routeSayfa("Müşteri Rapor", this);
      }
    },
    etutPage(musteriID, proje) {
      //console.log(musteriID)
      //console.log(proje)
      //console.log(this.yetkiTuru)
      if (
          this.yetkiTuru == 1 ||
          (this.yetkiTuru == 2 && this.yetkiListesi.includes("YK-musteriP100"))
      ) {
        this.$store.commit("changeMusteriDetayData", musteriID);
        this.$store.commit("changeProjeDetayData", proje);
        functions.routeSayfa("Müşteri Rapor", this);
      }
    },
    routeMusteri(name, scope) {
      if (scope) {
        this.$store.commit("changeMusteriDetayData", scope);
      }
      functions.routeSayfa(name, this);
    },
    getProjeListesi() {
      try {
        this.loading = true;
        projeService
            .projeListesi(this.selectIcon, this.postPage, 50, 10)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                if (this.yetkiTuru == 1) {
                  this.projelerList = response.data;
                  this.total = parseInt(response.count);
                }
                this.tarihler = functions.daysInMonths();
                if (this.projelerList.length > 0) {
                  this.projelerList.forEach((proje) => {
                    proje.toplamProjeSuresi = 0;
                    proje.baslamaGecenSure = 0;
                    proje.baslamaSureYuzde = 0;
                    proje.kalanSure = 0;
                    proje.toplamProjeSuresi = getDays(
                        proje.baslangicTarihi,
                        proje.bitisTarihi
                    );
                    //başlangıç Tarihi bugun ve öncesi ise hesaplansın değilse
                    projeBasladiMi(
                        moment().format("YYYY-MM-DD"),
                        proje.baslangicTarihi
                    )
                        ? (proje.baslamaGecenSure = getDays(
                            proje.baslangicTarihi,
                            moment().format("YYYY-MM-DD")
                        ))
                        : (proje.baslamaGecenSure = 0);
                    proje.kalanSure =
                        proje.toplamProjeSuresi - proje.baslamaGecenSure;
                    if (proje.kalanSure < 0) {
                      proje.kalanSure = 0;
                    }
                    proje.baslamaSureYuzde = parseFloat(
                        (proje.baslamaGecenSure * 100) / proje.toplamProjeSuresi
                    ).toFixed(1);
                    if (proje.baslamaSureYuzde > 100) {
                      proje.baslamaSureYuzde = 100;
                    }
                    proje.toplamSozlesme = 0;
                    proje.bitenSozlesme = 0;
                    // burada projede bulunan sozlesmeleri oranlarını yuzdelik değerlere ceviriyoruz
                    // yuzde deger = sozlesmeYuzde * 100 / toplamSozlesmelerYuzdeDegerleriToplamlari
                    // 2 foreach donmesinin sebebi once projedeki sözleşmelerin yuzdelerinin toplamlarına ihtiyacımız olmasından! 2.foreach yuzdelik orana cevirmek için.
                    if (proje.sozlesmeler) {
                      proje.sozlesmeler.forEach((sozlesme) => {
                        proje.toplamSozlesme += parseFloat(sozlesme.yuzdeDeger);
                        //proje.bitenSozlesme += sozlesmeBittiMi(moment().format("YYYY-MM-DD"), sozlesme.isTeslimTarihi) ? parseFloat(sozlesme.yuzdeDeger) : 0
                        proje.bitenSozlesme +=
                            sozlesme.isDurumu == 20 || sozlesme.isDurumu == 30
                                ? parseFloat(sozlesme.yuzdeDeger)
                                : 0;
                      });
                      proje.bitmeYuzdesi = !isNaN(
                          parseFloat(
                              (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                          )
                      )
                          ? parseFloat(
                              (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                          ).toFixed(1)
                          : 0;
                      let yuzdeFarki =
                          parseFloat(proje.baslamaSureYuzde) -
                          parseFloat(proje.bitmeYuzdesi);
                      if (yuzdeFarki > 5) {
                        this.geriProje.push(proje); // Fark 5'ten büyük ise geriProje'ye projeyi ekle
                      } else {
                        this.normalProje.push(proje);
                      }
                      proje.sozlesmeler.forEach((s) => {
                        s.toplamYuzde = parseFloat(
                            (parseInt(s.yuzdeDeger) * 100) / proje.toplamSozlesme
                        ).toFixed(1);
                        // sozlesmenin başlangıc ve bitiş tarihleri arasında hangi aylar var ve o aylarda kac gun is yapılmıs(yapılacak) objesini donduren fonksiyon
                        // bu kodlar sozlesmeleri hangi tarihlerde yani hangi aylarda yuzde kac iş yapılması gerektiğini temsil ediyor. O yüzden ay ay kac gun calısıldıgı yuzdeliğe ceviriliyor (o ay calısılan gun * 100 / aylarda calısılan günlerin toplamı)
                        s.toplamCalisilanGun = 0;
                        s.sozlesmeAylari = getMonthsBetween(
                            s.baslamaTarihi,
                            s.isTeslimTarihi
                        );
                        s.sozlesmeAylari.forEach((ay) => {
                          s.toplamCalisilanGun += parseInt(ay.days);
                        });
                        s.sozlesmeAylari.forEach((ay, index) => {
                          // eger tek bir ay içinde yapılcaksa sozlesme o zaman sozlesme gunlerini ayda bulunan günlere göre yuzdelik orana cevirdik
                          // birden fazla ise aralık yani sözleşmek ocak subat mart aylarında geçerli ise örneğin, o zaman ocak ayı için yuzdelik hesap toplam is gunune gore hesaplandı. ocak ayındaki gun * 100 / toplam iş gunu şeklinde
                          var daysInMonth = this.tarihler.find(
                              (e) => e.monthIndex == ay.monthIndex
                          );

                          ay.dayPercentage =
                              s.sozlesmeAylari.length == 1
                                  ? parseFloat(
                                      (parseInt(ay.days) * 100) / daysInMonth.days
                                  ).toFixed(1)
                                  : index > 0 && index < s.sozlesmeAylari.length - 1
                                      ? 100
                                      : parseFloat(
                                          (parseInt(ay.days) * 100) / daysInMonth.days
                                      ).toFixed(1);
                        });
                        // ---------------------------------------------------------------------------------------
                        // Bugunun tarihi aralık olarak secilen gün ile karsılaştırılıyor ve bulunulan gün başlama tarihinden önce ise bitti = 0, tarihlerin arasında ise bitti = 1, bitiş tarihini geçmiş ise bitti = 2 (bitti değişkeni o sözleşmenin durumunu temsil ediyor.)
                        s.bitti = checkDateRange(s.baslamaTarihi, s.bitisTarihi); // durum = 0 başlamadı, durum = 1 başlandı, durum = 2 bitti
                        // console.log(proje.projeBaslik)
                        // console.log(s)
                      });
                    }
                    if (proje.sozlesmeler == undefined) {
                      this.sozlesmesiOlmayanProje.push(proje);
                    }
                  });
                  this.projelerList.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });

                  this.geriProje.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });

                  this.normalProje.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });
                }
              }
              this.loading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.projelerList = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    getEtutListesi() {
      try {
        this.loading = true;
        projeService
            .projeListesi(this.selectIcon, this.postPage, 50, 20)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                if (this.yetkiTuru == 1) {
                  this.etutList = response.data;
                  this.total = parseInt(response.count);
                }
                this.tarihler = functions.daysInMonths();
                if (this.etutList.length > 0) {
                  this.etutList.forEach((proje) => {
                    proje.toplamProjeSuresi = 0;
                    proje.baslamaGecenSure = 0;
                    proje.baslamaSureYuzde = 0;
                    proje.kalanSure = 0;
                    proje.toplamProjeSuresi = getDays(
                        proje.baslangicTarihi,
                        proje.bitisTarihi
                    );
                    //başlangıç Tarihi bugun ve öncesi ise hesaplansın değilse
                    projeBasladiMi(
                        moment().format("YYYY-MM-DD"),
                        proje.baslangicTarihi
                    )
                        ? (proje.baslamaGecenSure = getDays(
                            proje.baslangicTarihi,
                            moment().format("YYYY-MM-DD")
                        ))
                        : (proje.baslamaGecenSure = 0);
                    proje.kalanSure =
                        proje.toplamProjeSuresi - proje.baslamaGecenSure;
                    if (proje.kalanSure < 0) {
                      proje.kalanSure = 0;
                    }
                    proje.baslamaSureYuzde = parseFloat(
                        (proje.baslamaGecenSure * 100) / proje.toplamProjeSuresi
                    ).toFixed(1);
                    if (proje.baslamaSureYuzde > 100) {
                      proje.baslamaSureYuzde = 100;
                    }
                    proje.toplamSozlesme = 0;
                    proje.bitenSozlesme = 0;
                    proje.bitmeYuzdesi = 0;
                    // burada projede bulunan sozlesmeleri oranlarını yuzdelik değerlere ceviriyoruz
                    // yuzde deger = sozlesmeYuzde * 100 / toplamSozlesmelerYuzdeDegerleriToplamlari
                    // 2 foreach donmesinin sebebi once projedeki sözleşmelerin yuzdelerinin toplamlarına ihtiyacımız olmasından! 2.foreach yuzdelik orana cevirmek için.
                    if (proje.sozlesmeler) {
                      proje.sozlesmeler.forEach((sozlesme) => {
                        proje.toplamSozlesme += parseFloat(sozlesme.yuzdeDeger);
                        //proje.bitenSozlesme += sozlesmeBittiMi(moment().format("YYYY-MM-DD"), sozlesme.isTeslimTarihi) ? parseFloat(sozlesme.yuzdeDeger) : 0
                        proje.bitenSozlesme +=
                            sozlesme.isDurumu == 20 || sozlesme.isDurumu == 30
                                ? parseFloat(sozlesme.yuzdeDeger)
                                : 0;
                      });
                      proje.bitmeYuzdesi = parseFloat(
                          (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                      ).toFixed(1);
                      let yuzdeFarki =
                          parseFloat(proje.baslamaSureYuzde) -
                          parseFloat(proje.bitmeYuzdesi);
                      if (yuzdeFarki > 5) {
                        this.geriProje.push(proje); // Fark 5'ten büyük ise geriProje'ye projeyi ekle
                      } else {
                        this.normalProje.push(proje);
                      }
                      proje.sozlesmeler.forEach((s) => {
                        s.toplamYuzde = parseFloat(
                            (parseInt(s.yuzdeDeger) * 100) / proje.toplamSozlesme
                        ).toFixed(1);
                        // sozlesmenin başlangıc ve bitiş tarihleri arasında hangi aylar var ve o aylarda kac gun is yapılmıs(yapılacak) objesini donduren fonksiyon
                        // bu kodlar sozlesmeleri hangi tarihlerde yani hangi aylarda yuzde kac iş yapılması gerektiğini temsil ediyor. O yüzden ay ay kac gun calısıldıgı yuzdeliğe ceviriliyor (o ay calısılan gun * 100 / aylarda calısılan günlerin toplamı)
                        s.toplamCalisilanGun = 0;
                        s.sozlesmeAylari = getMonthsBetween(
                            s.baslamaTarihi,
                            s.isTeslimTarihi
                        );
                        s.sozlesmeAylari.forEach((ay) => {
                          s.toplamCalisilanGun += parseInt(ay.days);
                        });
                        s.sozlesmeAylari.forEach((ay, index) => {
                          // eger tek bir ay içinde yapılcaksa sozlesme o zaman sozlesme gunlerini ayda bulunan günlere göre yuzdelik orana cevirdik
                          // birden fazla ise aralık yani sözleşmek ocak subat mart aylarında geçerli ise örneğin, o zaman ocak ayı için yuzdelik hesap toplam is gunune gore hesaplandı. ocak ayındaki gun * 100 / toplam iş gunu şeklinde
                          var daysInMonth = this.tarihler.find(
                              (e) => e.monthIndex == ay.monthIndex
                          );

                          ay.dayPercentage =
                              s.sozlesmeAylari.length == 1
                                  ? parseFloat(
                                      (parseInt(ay.days) * 100) / daysInMonth.days
                                  ).toFixed(1)
                                  : index > 0 && index < s.sozlesmeAylari.length - 1
                                      ? 100
                                      : parseFloat(
                                          (parseInt(ay.days) * 100) / daysInMonth.days
                                      ).toFixed(1);
                        });
                        // ---------------------------------------------------------------------------------------
                        // Bugunun tarihi aralık olarak secilen gün ile karsılaştırılıyor ve bulunulan gün başlama tarihinden önce ise bitti = 0, tarihlerin arasında ise bitti = 1, bitiş tarihini geçmiş ise bitti = 2 (bitti değişkeni o sözleşmenin durumunu temsil ediyor.)
                        s.bitti = checkDateRange(s.baslamaTarihi, s.bitisTarihi); // durum = 0 başlamadı, durum = 1 başlandı, durum = 2 bitti
                        // console.log(proje.projeBaslik)
                        // console.log(s)
                      });
                    }
                    if (proje.sozlesmeler == undefined) {
                      this.sozlesmesiOlmayanProje.push(proje);
                    }
                  });
                  this.etutList.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });

                  this.geriProje.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });

                  this.normalProje.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });
                }
              }
              this.loading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.etutList = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    getKapsamListesi() {
      try {
        this.loading = true;
        kapsamService
            .projeListesi(this.selectIcon, this.postPage, this.sizes)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                this.projelerList = response.data;
                this.total = parseInt(response.count);

                this.tarihler = functions.daysInMonths();
                if (this.projelerList.length > 0) {
                  this.projelerList.forEach((proje) => {
                    proje.toplamProjeSuresi = 0;
                    proje.baslamaGecenSure = 0;
                    proje.baslamaSureYuzde = 0;
                    proje.kalanSure = 0;

                    proje.toplamProjeSuresi = getDays(
                        proje.baslangicTarihi,
                        proje.bitisTarihi
                    );

                    //başlangıç Tarihi bugun ve öncesi ise hesaplansın değilse

                    projeBasladiMi(
                        moment().format("YYYY-MM-DD"),
                        proje.baslangicTarihi
                    )
                        ? (proje.baslamaGecenSure = getDays(
                            proje.baslangicTarihi,
                            moment().format("YYYY-MM-DD")
                        ))
                        : (proje.baslamaGecenSure = 0);

                    proje.kalanSure =
                        proje.toplamProjeSuresi - proje.baslamaGecenSure;
                    if (proje.kalanSure < 0) {
                      proje.kalanSure = 0;
                    }

                    proje.baslamaSureYuzde = parseFloat(
                        (proje.baslamaGecenSure * 100) / proje.toplamProjeSuresi
                    ).toFixed(1);
                    if (proje.baslamaSureYuzde > 100) {
                      proje.baslamaSureYuzde = 100;
                    }
                    //  console.log(proje)

                    proje.toplamSozlesme = 0;
                    proje.bitenSozlesme = 0;
                    // burada projede bulunan sozlesmeleri oranlarını yuzdelik değerlere ceviriyoruz
                    // yuzde deger = sozlesmeYuzde * 100 / toplamSozlesmelerYuzdeDegerleriToplamlari
                    // 2 foreach donmesinin sebebi once projedeki sözleşmelerin yuzdelerinin toplamlarına ihtiyacımız olmasından! 2.foreach yuzdelik orana cevirmek için.

                    if (proje.sozlesmeler) {
                      proje.sozlesmeler.forEach((sozlesme) => {
                        proje.toplamSozlesme += parseFloat(sozlesme.yuzdeDeger);
                        //proje.bitenSozlesme += sozlesmeBittiMi(moment().format("YYYY-MM-DD"), sozlesme.isTeslimTarihi) ? parseFloat(sozlesme.yuzdeDeger) : 0
                        proje.bitenSozlesme +=
                            sozlesme.isDurumu == 20 || sozlesme.isDurumu == 30
                                ? parseFloat(sozlesme.yuzdeDeger)
                                : 0;
                      });

                      proje.bitmeYuzdesi = parseFloat(
                          (proje.bitenSozlesme * 100) / proje.toplamSozlesme
                      ).toFixed(1);

                      let yuzdeFarki =
                          parseFloat(proje.baslamaSureYuzde) -
                          parseFloat(proje.bitmeYuzdesi);

                      if (yuzdeFarki > 5) {
                        this.geriProje.push(proje); // Fark 5'ten büyük ise geriProje'ye projeyi ekle
                      } else {
                        this.normalProje.push(proje);
                      }

                      proje.sozlesmeler.forEach((s) => {
                        s.toplamYuzde = parseFloat(
                            (parseInt(s.yuzdeDeger) * 100) / proje.toplamSozlesme
                        ).toFixed(1);

                        // sozlesmenin başlangıc ve bitiş tarihleri arasında hangi aylar var ve o aylarda kac gun is yapılmıs(yapılacak) objesini donduren fonksiyon
                        // bu kodlar sozlesmeleri hangi tarihlerde yani hangi aylarda yuzde kac iş yapılması gerektiğini temsil ediyor. O yüzden ay ay kac gun calısıldıgı yuzdeliğe ceviriliyor (o ay calısılan gun * 100 / aylarda calısılan günlerin toplamı)
                        s.toplamCalisilanGun = 0;
                        s.sozlesmeAylari = getMonthsBetween(
                            s.baslamaTarihi,
                            s.isTeslimTarihi
                        );
                        s.sozlesmeAylari.forEach((ay) => {
                          s.toplamCalisilanGun += parseInt(ay.days);
                        });
                        s.sozlesmeAylari.forEach((ay, index) => {
                          // eger tek bir ay içinde yapılcaksa sozlesme o zaman sozlesme gunlerini ayda bulunan günlere göre yuzdelik orana cevirdik
                          // birden fazla ise aralık yani sözleşmek ocak subat mart aylarında geçerli ise örneğin, o zaman ocak ayı için yuzdelik hesap toplam is gunune gore hesaplandı. ocak ayındaki gun * 100 / toplam iş gunu şeklinde
                          var daysInMonth = this.tarihler.find(
                              (e) => e.monthIndex == ay.monthIndex
                          );

                          ay.dayPercentage =
                              s.sozlesmeAylari.length == 1
                                  ? parseFloat(
                                      (parseInt(ay.days) * 100) / daysInMonth.days
                                  ).toFixed(1)
                                  : index > 0 && index < s.sozlesmeAylari.length - 1
                                      ? 100
                                      : parseFloat(
                                          (parseInt(ay.days) * 100) / daysInMonth.days
                                      ).toFixed(1);
                        });
                        // ---------------------------------------------------------------------------------------

                        // Bugunun tarihi aralık olarak secilen gün ile karsılaştırılıyor ve bulunulan gün başlama tarihinden önce ise bitti = 0, tarihlerin arasında ise bitti = 1, bitiş tarihini geçmiş ise bitti = 2 (bitti değişkeni o sözleşmenin durumunu temsil ediyor.)
                        s.bitti = checkDateRange(s.baslamaTarihi, s.bitisTarihi); // durum = 0 başlamadı, durum = 1 başlandı, durum = 2 bitti
                        // console.log(proje.projeBaslik)
                        // console.log(s)
                      });
                    }
                    if (proje.sozlesmeler == undefined) {
                      this.sozlesmesiOlmayanProje.push(proje);
                    }
                  });

                  this.projelerList.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });

                  this.geriProje.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });

                  this.normalProje.sort((a, b) => {
                    const aBaslamaYuzde = parseFloat(a.baslamaSureYuzde);
                    const bBaslamaYuzde = parseFloat(b.baslamaSureYuzde);
                    const aBitmeYuzde = parseFloat(a.bitmeYuzdesi);
                    const bBitmeYuzde = parseFloat(b.bitmeYuzdesi);
                    const aYuzdeFarki = aBaslamaYuzde - aBitmeYuzde;
                    const bYuzdeFarki = bBaslamaYuzde - bBitmeYuzde;

                    if (aYuzdeFarki >= 5 && bYuzdeFarki < 5) {
                      return -1; // a önce b'den önce gösterilir
                    } else if (aYuzdeFarki < 5 && bYuzdeFarki >= 5) {
                      return 1; // b önce a'dan önce gösterilir
                    } else {
                      // Başlama ve bitiş yüzdesi farkı 5'ten büyük veya küçük değilse veya her ikisi de 5'ten büyükse
                      // Projelerin başlama tarihine göre sıralama yapılır
                      const aTarih = new Date(a.baslangicTarihi);
                      const bTarih = new Date(b.baslangicTarihi);
                      return aTarih - bTarih; // küçükten büyüğe doğru sıralama
                    }
                  });
                }
              }
              this.loading = false;
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.projeList = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },
    format(percentage) {
      return percentage === 100 ? "Full" : `${percentage}%`;
    },
    musteriSecimi(event) {
      var item = this.musteriList.find((el) => el.musteriID == event);
      this.projeList = item ? item.projeler : [];
    },
    getYoneticiListesi() {
      this.loading = true;
      yoneticiService
          .yoneticiListe2(1, 1, 0, 5)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
              this.yoneticiListesi = response.data;
              this.total = response.count;
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.yoneticiListesi = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
    },
    getTatilListesi() {
      try {
        var currentYear = getCurrentYear();
        tatilService
            .currentMounthHoliday(this.postPage, this.sizes, currentYear)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              response.data.forEach(function (e) {
                //e.baslamaTarihi = moment(e.baslamaTarihi).format('DD-MM-YYYY');
                e.baslamaTarihi = moment(e.baslamaTarihi).format("DD.MM.YYYY");
                e.bitisTarihi = moment(e.bitisTarihi).format("DD.MM.YYYY");
              });
              if (response.status == 200) {
                this.tatilTotal = parseInt(response.count);
                this.tatilList = response.data;
              }
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.tatilList = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
              this.loading = false;
            });
      } catch (e) {
      }
    },
    getGecikenSozlesmeler() {
      try {
        personelService
            .gecikenSozlesmeler(this.postPage, this.sizes, 2)
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                this.gecikmeler = response.data;
                this.totalGecikme = response.count;
              }
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.gecikmeler = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
            });
      } catch (e) {
      }
    },
    getDegerlendirmeler() {
      try {
        kapsamService
            .personelDegerlendirmeListesi(
                this.postPage,
                this.sizes,
                this.selectIcon
            )
            .then((response) => {
              localStorage.setItem("userDataBGSurec", response.token);
              if (response.status == 200) {
                if (this.yetkiTuru == 1) {
                  this.degerlendirmeler = response.data;
                  this.totalDegerlendirme = parseInt(response.count);
                }
                if (this.yetkiTuru == 2) {
                  this.degerlendirmeler = response.data.filter(
                      (degerlendirme) => {
                        return degerlendirme.degerlendirenID === this.yoneticiID;
                      }
                  );
                  this.totalDegerlendirme = parseInt(
                      this.degerlendirmeler.length
                  );
                }
              }
            })
            .catch((err) => {
              if (err.responseJSON) {
                let error = err.responseJSON;
                if (error.status == 401) {
                  notification.Status(150, this);
                } else if (error.status == 404) {
                  this.degerlendirmeler = [];
                } else notification.Status("errorMsg", this, error.errMsg);
              } else {
                //notification.Status(503, this)
              }
            });
      } catch (e) {
      }
    },
    getTutanakList() {
      tutanakService
          .tutanakListesi(1, this.postPage, this.sizes)
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem("userDataBGSurec", response.token);
              this.tutanakListesi = response.data;
              this.totalTutanak = response.count;
            }
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.tutanakListesi = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
          });
    },
  },
};

function getMonthsBetween(start, end) {
  moment.locale("tr");
  const results = [];
  for (
      let m = moment(start);
      m.isSameOrBefore(end, "month");
      m.add(1, "months")
  ) {
    //for (let m = moment(start); m.isBefore(end); m.add(1, 'months')) {
    const monthStart = moment(m).startOf("month");
    const monthEnd = moment(m).endOf("month");

    let daysInMonth = monthEnd.diff(monthStart, "days") + 1;

    if (m.isSame(moment(start).startOf("month"), "month")) {
      daysInMonth -= moment(start).date();
    }
    if (m.isSame(moment(end).endOf("month"), "month")) {
      daysInMonth -= monthEnd.date() - moment(end).date();
    }

    results.push({
      month: monthStart.format("MMMM"),
      monthIndex: parseInt(monthStart.month()) + 1,
      days: daysInMonth, //- 1
    });
  }
  return results;
}

function checkDateRange(start, end) {
  const today = moment();
  if (today.isBefore(start, "day")) {
    return 0;
  } else if (today.isBetween(start, end, "day")) {
    return 1;
  } else {
    return 2;
  }
}

function getDays(tarih1, tarih2) {
  const tarih1Moment = moment(tarih1);
  const tarih2Moment = moment(tarih2);
  const gunFarki = tarih2Moment.diff(tarih1Moment, "days");

  return gunFarki;
}

function sozlesmeBittiMi(tarih1, tarih2) {
  const momentTarih1 = moment(tarih1);
  const momentTarih2 = moment(tarih2);

  return momentTarih1.isAfter(momentTarih2);
}

function projeBasladiMi(bugun, baslangicTarihi) {
  const momentBugun = moment(bugun);
  const momentBaslangic = moment(baslangicTarihi);

  return momentBugun.isAfter(momentBaslangic);
}

function getCurrentYear() {
  var date = new Date();
  var year = date.getFullYear();
  return year;
}
</script>
<style lang="scss">
// Mobile Dash
.mobile-incele-button {
  bottom: 0;
  position: absolute;
  padding: 4px 33px;
  display: block;
  background-color: #d7e7e3;

  span {
    font-size: 14px;
  }
}

.mobile-divider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.proje-card:nth-child(odd) {
  border-left: 4px solid #65b741;
}

.proje-card:nth-child(even) {
  border-left: 4px solid #ffa33c;
}

.mobile-badge .el-card:not(.proje-card) {
  display: flex;
  place-content: center;
  place-items: center;
  height: 60px;
  margin: 2px;

  span {
    color: #606266;
  }

  .el-button {
    display: flex;
    place-content: center;
    place-items: center;
    height: 60px;
    width: 150px;
    border: none;
  }
}

.proje-card .el-card__body {
  padding: 10px;
}

.mobile-badge {
  display: flex;
}

.el-badge__content {
  z-index: 20;
}

.mobile-dash {
  .el-badge__content.is-fixed {
    right: 17px !important;
  }

  .el-divider--horizontal {
    margin: 5px 0;
  }
}
</style>
<style lang="scss">
.table-progress-divider {
  margin: 10px 5px 6px 10px !important;
}

.geciken-proje {
  .el-progress-bar .el-progress-bar__outer {
    width: 93% !important;
  }

  table tr th {
    background-color: #b9d9ff !important;
    color: #665454;
    padding: 2px;
    font-weight: 500;
    font-family: system-ui;
  }

  table tr td:nth-child(1) {
    border-left: 2px solid #ee6983;
  }
}

.normal-proje {
  .el-progress-bar .el-progress-bar__outer {
    width: 93% !important;
  }

  table tr th {
    background-color: #b9d9ff !important;
    color: #665454;
    padding: 2px;
    font-weight: 500;
    font-family: system-ui;
  }

  table tr td:nth-child(1) {
    border-left: 2px solid #86b6f6;
  }
}

.etut-proje {
  .el-progress-bar .el-progress-bar__outer {
    width: 93% !important;
  }

  table tr th {
    background-color: #b9d9ff !important;
    color: #665454;
    padding: 2px;
    font-weight: 500;
    font-family: system-ui;
  }

  table tr td:nth-child(1) {
    border-left: 2px solid #52d3d8;
  }
}

// < Table Color

.collapse-list .gecikme-area table th div {
  background-color: #ffd5d5;
  padding: 2px;
  color: #272626;
  font-weight: 500;
  font-family: system-ui;
}

.collapse-list .gecikme-area table th:nth-child(4) div {
  padding-right: 20px;
}

.collapse-list .gecikme-area table th:nth-child(4) div {
  padding-right: 20px;
}

.collapse-list .degerlendirme-area table th div {
  background-color: #ffd5d5;
  padding: 2px;
  color: #272626;
  font-weight: 500;
  font-family: system-ui;
}

.collapse-list .yonetici-area table th div {
  background-color: #ffd5d5;
  padding: 2px;
  color: #272626;
  font-weight: 500;
  font-family: system-ui;
}

.collapse-list .tatil-area table th div {
  background-color: #bde0e3;
  padding: 2px;
  color: #272626;
  font-weight: 500;
  font-family: system-ui;
}

.collapse-list .tutanak-area table th div {
  background-color: #bde0e3;
  padding: 2px;
  color: #272626;
  font-weight: 500;
  font-family: system-ui;
}

.collapse-list .faaliyet-area table th div {
  background-color: #bde0e3;
  padding: 2px;
  color: #272626;
  font-weight: 500;
  font-family: system-ui;
}

//  Table Color >

.collapse-list .geri-proje-area .el-collapse-item__header {
  background-color: #53aee3 !important;
}

.collapse-list .normal-proje-area .el-collapse-item__header {
  background-color: #53aee3 !important;
}

.collapse-list .etut-area .el-collapse-item__header {
  background-color: #53aee3 !important;
}

.collapse-list .gecikme-area .el-collapse-item__header {
  background-color: #f79b9b !important;
}

.collapse-list .degerlendirme-area .el-collapse-item__header {
  background-color: #f79b9b !important;
}

.collapse-list .yonetici-area .el-collapse-item__header {
  background-color: #f79b9b !important;
}

.collapse-list .tatil-area .el-collapse-item__header {
  background-color: #62c5c9 !important;
}

.collapse-list .tutanak-area .el-collapse-item__header {
  background-color: #62c5c9 !important;
}

.collapse-list .faaliyet-area .el-collapse-item__header {
  background-color: #62c5c9 !important;
}

html,
body,
div,
template {
  font-size: 12px !important;
}

.el-table__empty-block {
  display: none;
}

.el-row:first-child {
  margin-top: 0px !important;
}

#musteriSelect {
  height: 34px !important;
}

.noItem {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
}

#anasayfa {
  .el-table__cell {
    .cell {
      padding: 0 !important;
    }
  }

  .el-progress-bar__outer {
    background: rgb(252, 252, 252) !important;
  }

  thead th {
    background: #c7e3f6 !important;
    color: #00385a !important;
    line-height: 35px !important;
  }
}

.vr {
  border-left: 3px solid #e67e22;
  height: 50px;
  margin-left: 30px;
  line-height: 25px;
  justify-content: space-around;
}

.row {
  display: flex;
  justify-content: space-around;
}

.proje {
  margin-left: -7.5px;
  margin-right: -7.5px;
  border: 1px solid rgb(234, 252, 255);
  background: white;
  box-shadow: 5px 5px 5px #f1f1f1;
  padding: 6px 0 10px 0;
}

#anasayfa .el-progress-bar__outer {
  background: #efefef !important;
}
</style>
<style>
.pointer {
  cursor: pointer;
}

.padding-left-5,
.pl-5 {
  padding-left: 5px;
  padding-bottom: 2px;
}

.el-collapse-item .el-row {
  top: 5px !important;
}

.el-collapse-item__header {
  height: 36px !important;
}

.baddge .el-badge__content {
  background-color: red !important;
  color: white;
  width: 25px;
  height: 25px;
  padding: 0;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: -20px !important;
  margin-left: -1px !important;
}

.anasayfa .el-collapse-item__wrap {
  will-change: height;
  background-color: #f3f7fa;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #ebeef5;
}

.proje-gun-row {
  text-align: center;
  justify-content: space-between;
  display: flex;
  border-top: 1px solid #eff4ff;
  padding-top: 5px;
}

.el-progress-bar {
  padding-right: 12px;
}

.el-progress__text {
  position: absolute;
  right: 0;
}

.el-progress-bar__outer {
  width: 90%;
}

.ps > .ps__scrollbar-y-rail,
.ps > .ps__rail-y {
  z-index: 100 !important;
}
</style>