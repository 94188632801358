import WsProvider from "./provider/WSProvider";

const serviceName = "webservis";
const moduleName = "tutanak";

const tutanakService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    tutanakListesiAra(
        projeID,
        durum,
        baslangic,
        limit,
        sayi,
        konu,
        icerik,
        yetkiliAdSoyad,
        olusturan,
        yer,
        tarih,
        firma = ""
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            sayi,
            projeID: projeID || "",
            konu,
            icerik,
            yetkiliAdSoyad,
            olusturan,
            yer,
            olusumTarihi: tarih,
            firma,
            serviceName: moduleName,
            methodName: "tutanakArama",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    tutanakListesi(durum, baslangic, limit) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            durum: durum,
            baslangic: baslangic,
            limit,
            serviceName: moduleName,
            methodName: "tutanakListesi",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    tutanakDurumChange(tutanakID, durum) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            tutanakID: tutanakID,
            durum: durum,
            serviceName: moduleName,
            methodName: "durumDegistir",
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },
    tutanakTanimla(
        musteriID,
        bildiri,
        iletisimTuru,
        durum,
        projeID,
        konu,
        kisaAciklama,
        icerik,
        sayi,
        yer,
        katilimcilar,
        yetkiliAdSoyad,
        sozlesmeID,
        yetkililer
    ) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");

        const param = {
            token: userDataBGSurec,
            musteriID: musteriID,
            bildiri: bildiri ? 1 : 0,
            iletisimTuru: iletisimTuru,
            durum: durum,
            projeID: projeID,
            konu: konu,
            icerik: icerik,
            sayi: sayi,
            yer: yer,
            kisaAciklama: kisaAciklama,
            katilimcilar: bildiri ? JSON.stringify(katilimcilar) : "",
            yetkililer: bildiri ? JSON.stringify(yetkililer) : "",
            yetkiliAdSoyad: yetkiliAdSoyad,
            sozlesmeID: sozlesmeID,
            methodName: "tutanakTanimla",
            serviceName: moduleName,
        };
        const result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
        return result;
    },


    getTutanakDetails(tutanakID, durum) {
        const userDataBGSurec = localStorage.getItem("userDataBGSurec");
        const param = {
            token: userDataBGSurec,
            tutanakID: tutanakID,
            durum: durum,
            serviceName: moduleName,
            methodName: "tutanakDetay",
        };
        return new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
};

export default tutanakService;
